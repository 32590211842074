import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';
import { compose } from 'redux';

import { publishGuide } from 'modules/api';
import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { CheckIconGuides, PaintPaletteIcon, CloseCircleIcon, InfographicIconFilled, GuideIconFilled, PiIcon } from 'modules/v2/common/components/SvgIcon';
import { InfoIcon } from 'modules/v2/common/components/SvgIcons';
import Spinner from 'modules/v2/common/components/SvgIcon/Spinner';
import { getRouteGuideCatalog, getMyCheckout } from 'modules/v2/routes/navigation';
import { cn } from 'modules/v2/common/utils';
import { PersonalInformationForm } from 'modules/v2/common/AtomicDesign/organisms';
import { getGuideContent, getGuideInfographics } from 'modules/api/strapi';
import { personalInformation } from 'modules/v2/pages/GuideCatalog/mocks/index';
import { strapiHtmlCreation, strapiHtmlInfographicCreation } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';
import NavigationArrow from 'modules/v2/common/AtomicDesign/atoms/NavigationArrow';
import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';

import { colors } from '../utils';

const EditGuide = ({ history, setIsPublishedGuide, profile, avatar }) => {

  const {
    data: { email, firstName, lastName, phone },
  } = profile;

  // TODO: phoneNumber, profileImage, companyName, address, companyLogo - Need to get from redux container
  const personalInfo = {
    name: `${firstName} ${lastName}`,
    email,
    profileImage: avatar?.data?.url || personalInformation.profileImage,
    ...personalInformation,
    phoneNumber: phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3') : personalInformation.phoneNumber,
  };

  const [isInfographicView, setIsInfographicView] = useState<boolean>(false);
  const [guideInfogra, setGuideInfogra] = useState({});
  const [selectedColor, setSelectedColor] = useState<string>('blue');
  const [hideOptions, setHideOptions] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('personal');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedDetails, setSelectedDetails] = useState({
    status: 'finalize',
    orderId: '123456',
  });

  const [loadingMessage, setLoadingMessage] = useState('Loading Guide Catalog...');
  const { data: guideResponse, isLoading: isGuideLoading } = useQuery('getGuideContent', getGuideContent);
  const { data: guideInfographics, isLoading: isGuideInfoLoading } = useQuery('getGuideInfographics', getGuideInfographics, {
    onSuccess: (data) => {
      setGuideInfogra(data?.data[0]?.attributes);
    }
  });

  let guideDetails;
  if (!isEmpty(guideResponse)) {
    guideDetails = guideResponse.data[0]?.attributes;
  }

  const handleViewSwitch = (view: 'guide' | 'infographic') => {
    console.log(view);
    
    setIsInfographicView(view === 'infographic');
  };

  const { mutate: publishGuideMutation, isLoading: isPublishing } = useMutation(
    publishGuide,
    {
      onSuccess: () => {
        setIsPublishedGuide(true);
      },
    }
  );

  const handlePublish = (status, orderId) => {
    history.push(getMyCheckout(status, orderId));
  };

  const setSideOptions = (value: string) => {
    setHideOptions(false);
    setActiveTab(value);
  };

  const handleGoBack = () => {
    history.push(getRouteGuideCatalog());
  };

  const getColorFromUrl = (url: string): string => {
    const urlParts = url.split('-');
    const lastPart = urlParts[urlParts.length - 1];
    return lastPart.split('.')[0];
  };

  const mockData = {
    title: 'A guide to selling your home',
    image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
    html: '<p>Some HTML content</p>',
    availableColors: ['blue', 'red', 'gold', 'purple', 'white'],
  };

  useEffect(() => {
    if (mockData?.image) {
      setSelectedColor(getColorFromUrl(mockData.image));
    }
  }, []);

  useEffect(() => {
    let newImage = mockData.image.replace(getColorFromUrl(mockData.image), selectedColor);
    newImage = isInfographicView ? newImage.replace('seller-guide', 'seller-infographic') : newImage;
  }, [selectedColor, isInfographicView]);

  const hasAvailableColors = mockData.availableColors.length > 0;

  let finalHTML;
  if(!isGuideLoading){
    finalHTML = strapiHtmlCreation(guideDetails, personalInfo, selectedColor);
  }

  let finalHTMLInfographic;
  if(!isGuideInfoLoading && !isEmpty(guideInfogra)){
    finalHTMLInfographic = strapiHtmlInfographicCreation(guideInfogra, personalInfo, selectedColor);
  }


  const cards = () => {
    if (!hasAvailableColors) {
      return <img src={mockData?.image} alt={mockData.title} />;
    }

    return (
      <>
        {mockData.availableColors?.map((color: string) => {
          let image = mockData.image.replace(getColorFromUrl(mockData.image), color);
          image = isInfographicView ? image.replace('seller-guide', 'seller-infographic') : image;
          return (
            <button
              type="button"
              onClick={() => setSelectedColor(color)}
              key={color}
              className="cursor-pointer"
            >
              <div className='w-7 h-7 rounded-full'>
                <div
                  className="w-[26px] h-[26px] border border-neutral-200 rounded-full flex items-center justify-center"
                  style={{ backgroundColor: colors[color] }}
                >
                  {color === selectedColor && <CheckIconGuides />}
                </div>
              </div>
            </button>
          );
        })}
      </>
    );
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (finalHTML && currentPage < finalHTML.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPageContent = () => {
    if (!finalHTML || !finalHTML[currentPage]) return '';
    return finalHTML[currentPage];
  };

  const getPageContentInfographic = () => {
    if (!finalHTMLInfographic) return '';
    return finalHTMLInfographic;
  };

  let setupGuides = <></>;

  if (activeTab === 'personal') {
    setupGuides = <PersonalInformationForm />;
  }

  if (activeTab === 'colors') {
    setupGuides = <div>
      <div className="flex gap-2 mb-[34px] py-2.5 px-3 bg-neutral-100 rounded-md">
        <div className="w-5 mt-0.5">
          <InfoIcon fill="#000000" className="w-5" />
        </div>
        <p>The color will be applied to both your guides and the infographic.</p>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-2">
        {cards()}
      </div>
    </div>;
  }

  return (
    <div className="fixed inset-0 bg-neutral-50">
      {isPublishing && (
        <div className="w-full h-dvh flex items-center justify-center bg-neutral-700 bg-opacity-50 fixed inset-0 z-20">
          <div className="flex flex-col gap-2 items-center justify-center bg-white w-80 h-52 rounded-xl shadow-box py-8">
            <Spinner />
            <span className="text-neutral-800 text-base font-bold mt-6">
              Publishing your guide...
            </span>
            <span className="text-neutral-500 text-sm font-medium">
              Please wait while we publish your guide.
            </span>
          </div>
        </div>
      )}

      <div className="w-full self-stretch h-16 flex-col justify-start items-center inline-flex">
        <div className="self-stretch h-16 px-6 pt-2 pb-[9px] bg-neutral-800 justify-between items-center inline-flex z-10 max-sm:px-3">
          <button
            type="button"
            className="justify-start items-center gap-2 flex cursor-pointer max-sm:gap-0.5"
            onClick={handleGoBack}
            aria-label="Back"
          >
            <ArrowLeft />
            <div className="text-neutral-50 text-sm font-bold leading-tight">
              Back
            </div>
          </button>
          <div className="justify-center items-center gap-1 flex">
            <button
              type="button"
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${!isInfographicView ? 'bg-neutral-700' : ''}`}
              onClick={() => handleViewSwitch('guide')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('guide');
              }}
              aria-label="Guide View"
            >
              <GuideIconFilled className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight max-sm:hidden">
                Guide
              </div>
            </button>
            <button
              type="button"
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${isInfographicView ? 'bg-neutral-700' : ''}`}
              onClick={() => handleViewSwitch('infographic')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('infographic');
              }}
              aria-label="Infographic View"
            >
              <InfographicIconFilled className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight 
                  max-sm:hidden">
                Infographic
              </div>
            </button>
          </div>
          <div className="justify-start items-center gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              <button
                type="button"
                className="h-[46px] px-[18px] py-[13px] bg-sky-400 rounded-md justify-center items-center flex cursor-pointer"
                onClick={() => handlePublish(selectedDetails.status, selectedDetails.orderId)}
                aria-label="Publish"
              >
                <div className="text-neutral-800 text-sm font-bold leading-tight">
                  Finalize
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex" style={{ height: 'calc(100vh - 64px)' }}>
        <div className="flex bg-white">
          <div className="w-[84px] border-r border-neutral-200 pl-2 pt-3.5">
            <div className="flex-row">
              <div className="flex gap-1">
                <button
                  type="button"
                  onClick={() => setSideOptions('personal')}
                  className={cn('rounded-md w-[68px] h-[68px] flex flex-col justify-center items-center gap-1', {
                    'bg-neutral-100': activeTab === 'personal',
                  })}
                >
                  <PiIcon />
                  <p className="text-xs font-bold">Personal</p>
                </button>
                {activeTab === 'personal' && <div className="
                  h-full
                  flex
                  relative
                  items-center
                  after:w-1
                  after:h-[54px]
                  after:content-[' ']
                  after:absolute
                  after:right-[-4px]
                  after:top-1
                  after:h-8
                  after:bg-blueBorder
                  after:transition-all
                  after:duration-200
                  after:rounded-l-md
                  hover:after:w-2
                " />}
              </div>
              <div className="flex gap-1">
                <button
                  type="button"
                  onClick={() => setSideOptions('colors')}
                  className={cn('rounded-md w-[68px] h-[68px] flex flex-col justify-center items-center gap-1', {
                    'bg-neutral-100': activeTab === 'colors',
                  })}
                >
                  <PaintPaletteIcon />
                  <p className="text-xs font-bold">Colors</p>
                </button>

                {activeTab === 'colors' && <div className="
                  h-full
                  flex
                  relative
                  items-center
                  after:w-1
                  after:h-[54px]
                  after:content-[' ']
                  after:absolute
                  after:right-[-4px]
                  after:top-1
                  after:h-8
                  after:bg-blueBorder
                  after:transition-all
                  after:duration-200
                  after:rounded-l-md
                  hover:after:w-2
                " />}
              </div>
            </div>
          </div>
          {!hideOptions && (
            <div className="w-[328px] max-sm:w-[300px] bg-white z-10">
              <div className="flex justify-between items-center border-b border-neutral-200 px-4 py-3.5">
                <p className="font-semibold">{activeTab === 'personal' ? 'Personal Information' : 'Available colors'}</p>
                <button
                  type="button"
                  className="p-2.5 bg-neutral-100 rounded-md cursor-pointer hover:bg-neutral-200"
                  onClick={() => setHideOptions(true)}
                  aria-label="Close color options"
                >
                  <CloseCircleIcon />
                </button>
              </div>
              <div
                className="px-3.5 py-6 overflow-y-auto"
                style={{ maxHeight: 'calc(100vh - 125px)' }}
              >{setupGuides}</div>
            </div>
          )}
        </div>

        {
          !isInfographicView ? (
            <div className="relative w-full flex items-center justify-center bg-neutral-700 p-4 overflow-y-auto max-sm:items-start">
              <div className="absolute left-4 z-10">
                <NavigationArrow 
                  direction="left" 
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                />
              </div>

              <iframe
                title="Guide preview"
                width="100%"
                height="100%"
                srcDoc={getPageContent()}
                src="demo_iframe_srcdoc.htm"
                className="absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 scale-60 origin-center w-[166.67%] h-[166.67%] overflow-hidden"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>

              <div className="absolute right-4 z-10">
                <NavigationArrow
                  direction="right"
                  onClick={handleNextPage}
                  disabled={!finalHTML || currentPage === finalHTML.length - 1}
                />
              </div>
            </div>
          ) : (
            <div className="relative w-full flex items-center justify-center bg-neutral-700 p-4 max-sm:items-start" style={{height: 'calc(100vh - 64px)'}}>
              <iframe
                title="Guide preview"
                width="1000px"
                height="2170px"
                srcDoc={getPageContentInfographic()}
                src="demo_iframe_srcdoc.htm"
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-35 origin-center"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default compose(withProfile, withAuth)(EditGuide);