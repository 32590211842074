import { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import classNames from 'classnames';

import { STATUS_SUCCESS } from 'store/status';
import { withGlobalProfile } from 'modules/dashboard/containers';
import { withProfile } from 'modules/v2/containers';
import { getMyCheckout, getRouteGuidePreview } from 'modules/v2/routes/navigation';
import { normalizeUrl, GuideCheckoutStatus, checkButton } from 'modules/v2/utils';
import { Link } from 'modules/v2/draft/components';

import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';
import { PersonalInformationForm } from 'modules/v2/common/AtomicDesign/organisms';
import AddressGoogleLookup from 'modules/v2/common/components/AddressGoogleLookup';
import { ChecklistIcon, CheckIconGuides, DangerTriangleIcon, QuestionCircleIcon } from 'modules/v2/common/components/SvgIcon';
import FisrtTimeAccess from './components/FisrtTimeAccess';

const GuidesContainer = ({ history, profile, globalProfile }) => {
  const { isRm, isBooks } = useSelector((data) => data.bundleAccess);
  const [isDigitalOnly, setIsDigitalOnly] = useState(false);
  const [firstNameUser, setFirstNameUser] = useState('');
  const [globalProfileData, setGlobalProfileData] = useState(null);
  const [isSetupCompleted, setIsSetupCompleted] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [setupForm, setSetupForm] = useState('personal-information');
  const [fieldValue, setFieldValue] = useState();
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [geocodeLocation, setGeocoderLocation] = useState(null);
  const [isAddressSameAsPublic, setIsAddressSameAsPublic] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isFormCompleted, setIsFormCompleted] = useState(false);

  const subHeader = () => {
    if (isSetupCompleted) return null;

    return 'Finish this setup checklist to get your resources ready to go.';
  };

  const boughtGuideData = {
    image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
    title: 'A Guide to Selling Your Home + Infographic',
    hasInfographic: true,
    qtd: 20,
  };


  useEffect(() => {
    if (profile) {
      const { firstName } = get(profile, 'data', '');
      setFirstNameUser(firstName);
    }
  }, [profile]);

  useEffect(() => {
    if (globalProfile && globalProfile.status === STATUS_SUCCESS) {
      // GLOBAL PROFILE DATA TO FILL SETUP CHECKLIST FORM
      setGlobalProfileData(globalProfile);
    }
  }, [globalProfile, globalProfileData]);

  let content = null;

  if (setupForm === 'personal-information') {
    content =
      <div>
        <p className='mb-6 text-base font-semibold'>Your Personal Information</p>
        <PersonalInformationForm />
        {/* <Button
          color='primary'
          className='mt-6'
          onClick={() => setIsFormCompleted(true)}
        >
          Save changes
        </Button> */}
      </div>
  }

  if (setupForm === 'address') {
    content =
      <div className='flex flex-col gap-6'>
        <p className='text-base font-semibold'>{`Your ${!isDigitalOnly ? 'Shipping' : ''} Address`}</p>
        <div className='flex items-center gap-3.5'>
          <button
            className={classNames('w-4 h-4 flex items-center justify-center rounded border border-neutral-200', isAddressSameAsPublic ? 'bg-primary-500' : 'bg-white')}
            onClick={() => setIsAddressSameAsPublic(!isAddressSameAsPublic)}
          >
            <CheckIconGuides width='10' height='10' />
          </button>
          <p>Same as your public address</p>
        </div>
        <AddressGoogleLookup
          setSelectedPlace={setSelectedPlace}
          setGeocoderLocation={setGeocoderLocation}
          setFieldValue={setFieldValue}
          setIsManualEntry={setIsManualEntry}
        />
        {/* <Button
          color='primary'
          onClick={() => console.log('save changes')}
        >
          Save changes
        </Button> */}

      </div>
  }

  const targetRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isFirstTime && !isFormCompleted) {
      targetRef.current.focus();
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const handleCheckout = (status, orderId) => {
    history.push(getMyCheckout(status, orderId));
  };

  const handlePreviewClick = () => {
    history.push(getRouteGuidePreview());
  };

  const mockGuides = [
    {
      id: 1,
      title: 'Finding Your Dream Home',
      image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
      description: "Buyer",
      orderStatus: 'new',
      orderId: null,
    },
    {
      id: 2,
      title: 'Your Ultimate Moving and Relocation Guides',
      image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
      description: "Moving (with Ads)",
      orderStatus: 'finalize',
      orderId: null,
    },
    {
      id: 3,
      title: 'A Guide to Selling Your Home + Infographic',
      image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
      description: "Moving (with Ads)",
      orderStatus: 'ordered',
      orderId: null,
      digitalGuide: 'http://dave.guide.live/blue-seller-guide',
    },
  ];

  const showYourGuides = () => {
    const guides = mockGuides.map((guide) => {
      if (guide.digitalGuide || guide.orderStatus === GuideCheckoutStatus.FINALIZE) {
        return (
          <div className='border-t border-neutral-200'>
            <div className='p-6 flex gap-[18px] font-semibold'>
              <img src={boughtGuideData.image} alt={boughtGuideData.title} className='w-[100px] h-[131px]' />
              <div>
                <div className='flex flex-wrap gap-2'>
                  <div className='text-base'>
                    {boughtGuideData.title}
                  </div>
                  <div className='py-0.5 px-1 rounded-md bg-neutral-100 text-sm h-fit'>
                    {boughtGuideData.qtd} Printed Copies
                  </div>
                </div>
                {guide.digitalGuide && (
                  <div className="overflow-visible mt-6">
                    <div className="w-[470px] max-sm:w-full">
                      <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                        Digital Guide
                        <Tooltip
                          content="Generate more leads and grow your business. Receive instant notifications for every new lead, so you can follow up quickly and win the business. "
                          trigger="hover"
                          style="dark"
                          arrow
                          placement="top"
                          theme={{
                            base:
                              'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                          }}
                        >
                          <QuestionCircleIcon />
                        </Tooltip>
                      </h5>
                      <Link
                        key="landingPage"
                        id="landingPage"
                        showPreview={false}
                        href={normalizeUrl(guide.digitalGuide)}
                        title={null}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    });
    return guides
  };

  const exploreYourGuides = () => {
    const guides = mockGuides.map((guide) => {
      const checkFinalizeStatus = guide?.orderStatus === GuideCheckoutStatus.FINALIZE;
      return (
        <div className='w-[196px]'>
          <div key={guide.id} className='w-[196px] relative group'>
            <div className='relative w-[196px] h-[252px] border border-neutral-300 rounded-md flex justify-center items-center'>
              <img src={guide.image} alt={guide.title} className='w-[196px] h-[250px] rounded-md object-fit' />
            </div>
            <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-full p-6">
              <Button
                type='outlined'
                className='mb-2 mx-6 w-[122px]'
                onClick={handlePreviewClick}
                size='sm'
                full
              >
                Preview
              </Button>
              <Button
                type='primary'
                className={classNames('mx-6', checkFinalizeStatus ? 'px-6' : '')}
                onClick={() => handleCheckout(guide?.orderStatus, guide?.orderId)}
                size='sm'
                full
              >
                {checkButton(guide?.orderStatus)}
              </Button>
            </div>
          </div>
          <div className='text-base font-semibold mt-3'>{guide.title}</div>
          <div className='text-sm font-medium text-neutral-600'>{guide.description}</div>
        </div>
      );
    });
    return guides;
  };

  return (
    <div>
      <Box className='my-6'>
        <div className='py-[22px] px-6 font-semibold text-base'>
          <p>Your Guides</p>
        </div>
        {showYourGuides()}
      </Box>

      {!isFormCompleted && (
        <>
          <Box className='mt-6'>
            <div className='flex items-center gap-3 py-2.5 px-3 bg-orange-50'>
              <DangerTriangleIcon />
              <div className='text-base text-orange-600 font-semibold'>Please complete the setup checklist below for us to ship your guides to you.</div>
            </div>
            <div className='flex w-full max-lg:flex-col'>
              <div className='p-6 w-full max-w-[300px] border-r border-neutral-200 max-lg:border-none' ref={targetRef} tabIndex={-1}>
                <button onClick={() => setSetupForm('personal-information')} className='w-full'>
                  <div className='text-lg font-semibold flex items-center gap-2.5 mb-3.5'>
                    <ChecklistIcon />
                    <span>Setup checklist</span>
                  </div>

                  <div className='text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md bg-neutral-100'>
                    <div className={classNames('w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200', setupForm === 'personal-information' ? 'bg-success-500' : 'bg-white')}>
                      <CheckIconGuides width='10' height='10' />
                    </div>
                    <span>Your Personal Information</span>
                  </div>
                </button>

                <button onClick={() => setSetupForm('address')} className='w-full'>
                  <div className='text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md bg-neutral-100 mt-2'>
                    <div className={classNames('w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200', setupForm === 'address' ? 'bg-success-500' : 'bg-white')}>
                      <CheckIconGuides width='10' height='10' />
                    </div>
                    <span>{isDigitalOnly ? 'Your Address' : 'Your Shipping Address'}</span>
                  </div>
                </button>
              </div>
              <div className='p-6 w-full max-w-screen-lg'>{content}</div>
            </div>
          </Box>
          <FisrtTimeAccess showModal={isFirstTime} setShowModal={setIsFirstTime} isDigitalOnly={isDigitalOnly} />
        </>
      )}

      {isFormCompleted && (
        <Box className='p-6'>
          <p className='text-lg font-semibold mb-1'>Explore More of Our Guides</p>
          <p className='text-sm font-medium text-neutral-600 mb-3.5'>Our Guides cover a range of topics designed to support your growth and success.</p>
          <div className='flex flex-wrap gap-3.5'>
            {exploreYourGuides()}
          </div>
        </Box>
      )}
    </div>
  );
};

export default compose(withProfile, withGlobalProfile)(GuidesContainer);