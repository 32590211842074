import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Form, Formik } from 'formik';

import { isReady } from 'store/status';
import { FormLabel, Loader, UserAvatar } from 'modules/v2/common/components';

import { ProfileAvatar } from 'modules/v2/common/components/SvgIcon';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { LogoUploader } from 'modules/v2/common/AtomicDesign/molecules';
import { withPreferencesDashboard, withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';
import { AddressForm } from 'modules/v2/common/AtomicDesign/organisms';
import { FileUploadModal } from 'modules/v2/components';
import validationSchema from './validationSchema';

const PersonalInformationForm = ({
  avatar,
  uploadAvatar,
  profile,
  updateProfile,
  getUserPreferences,
  preferences,
  logo,
  uploadLogo,
  hideAddress = false,
  isProfile = false,
}) => {
  const [loadingText, setLoadingText] = useState('Loading Profile');
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [fieldValue, setFieldValue] = useState();
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [geocodeLocation, setGeocoderLocation] = useState(null);
  const [hasPreferences, setHasPreferences] = useState(false);

  useEffect(() => {
    getUserPreferences();
  }, []);

  useEffect(() => {
    console.log('useEffect userPreferences', preferences);
    getUserPreferences();
  }, [preferences]);

  const { brokerLogo = '', companyName = '', publicAddress = '' } =
    profile.data.userPreferences?.data || {};

  const {
    data: {
      email: profileEmail,
      firstName: profileFirstName,
      lastName: profileLastName,
      phone: profilePhone,
    },
  } = profile;

  console.log({ brokerLogo, companyName, publicAddress });
  const hasAvatar = !!avatar?.data?.url;

  const [userData, setUserData] = useState(profile);
  const fields = {
    email: profileEmail,
    firstName: profileFirstName,
    lastName: profileLastName,
    phone: profilePhone,
    brokerLogo,
    companyName,
    publicAddress,
  };
  // if (isProfile) {
  //     fields = {
  //         email: profileEmail,
  //         firstName: profileFirstName,
  //         lastName: profileLastName,
  //         phone: profilePhone,
  //         brokerLogo,
  //         companyName,
  //         publicAddress,
  //     };
  // }

  // if (hasPreferences) {
  //     fields = {
  //         email: profileEmail,
  //         firstName: profileFirstName,
  //         lastName: profileLastName,
  //         phone: profilePhone,
  //         brokerLogo,
  //         companyName,
  //         publicAddress,
  //     };
  // }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleConfirm = (newFile) => {
    setIsOpen(false);

    uploadAvatar(newFile);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleProfileUpdate = (formFields) => {
    setLoadingText('Saving profile');

    updateProfile(formFields);
  };

  let content = <Loader title={loadingText} height="100%" />;

  const onSave = (values) => {
    const formFields = {
      ...values,
      brokerLogo: imageUrl,
      publicAddress: isManualEntry ? fieldValue : selectedPlace,
    };

    handleProfileUpdate(formFields);
  };

  if (isReady(profile.status)) {
    content = (
      <Formik initialValues={fields} validationSchema={validationSchema} onSubmit={onSave}>
        {({ dirty, values, setFieldValue, errors, handleSubmit, handleChange, isValid }) => {
          const formatPhoneNumber = (phoneNumber) => {
            const cleaned = phoneNumber.replace(/\D/g, '');
            const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');

            return formatted;
          };

          const handleChangeNumber = (event) => {
            const { name, value } = event.target;
            const formattedValue = formatPhoneNumber(value);
            setFieldValue(name, formattedValue);
          };

          const isDisabled = !isValid || !dirty;

          return (
            <Form onSubmit={handleSubmit}>
              {hasAvatar ? (
                <div className="h-[120px] mt-3.5 mb-6 flex items-center gap-6">
                  <div className="w-[120px]">
                    <UserAvatar
                      avatar={avatar}
                      bordered
                      defaultAvatar={<ProfileAvatar />}
                      size="120px"
                    />
                  </div>
                  <Button type="outlined" onClick={handleOpen}>
                    Change
                  </Button>
                </div>
              ) : (
                <Button
                  type="outlined"
                  onClick={handleOpen}
                  className="max-w-[492px] mt-3.5 mb-6"
                  full
                >
                  Upload photo
                </Button>
              )}
              <div className="flex gap-3.5 mb-3.5 max-lg:flex-col">
                <div className="flex-1 flex flex-col mb-3.5">
                  <FormLabel title="First name" htmlFor="firstName" required>
                    <TextInput
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName ? (
                      <span className="text-error-500 mt-2">{errors.firstName}</span>
                    ) : null}
                  </FormLabel>
                </div>
                <div className="flex-1 flex flex-col mb-3.5">
                  <FormLabel title="Last name" htmlFor="lastName" required>
                    <TextInput
                      id="lastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName ? (
                      <span className="text-error-500 mt-2">{errors.lastName}</span>
                    ) : null}
                  </FormLabel>
                </div>
              </div>

              <div className="flex gap-3.5 mb-3.5 max-lg:flex-col sm:flex-col">
                <div className="flex-1 flex flex-col mb-3.5">
                  <FormLabel title="Email address" required>
                    <TextInput
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      disabled={isProfile}
                    />
                  </FormLabel>
                </div>
                <div className="flex-1 flex flex-col mb-3.5">
                  <FormLabel title="Phone number" htmlFor="phone" required>
                    <TextInput
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formatPhoneNumber(values.phone)}
                      onChange={handleChangeNumber}
                      mask="999-999-9999"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    />
                    {errors.phone ? (
                      <span className="text-error-500 mt-2">{errors.phone}</span>
                    ) : null}
                  </FormLabel>
                </div>
              </div>
              {isOpen && (
                <FileUploadModal
                  isOpen={isOpen}
                  title="Upload avatar"
                  type="profile"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                />
              )}
              {!hideAddress && (
                <div className="border-y border-neutral-200 py-6 mb-6">
                  <div className="mb-6">
                    <p className="text-base font-semibold">Your Public Address</p>
                    <p className="text-sm font-medium text-neutral-500">
                      The address you enter here will appear in your printed materials. Please note,
                      this is not your shipping address.
                    </p>
                  </div>
                  <AddressForm />
                </div>
              )}
              <div className="p-3.5 bg-neutral-50 border border-neutral-200 rounded-md">
                <LogoUploader imageUrl={imageUrl} setImageUrl={setImageUrl} />
              </div>
              <Button
                buttonType="submit"
                className="mt-6"
                // disabled={isDisabled}
                // onClick={handleSubmit}
              >
                Save Changes
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return <div className="w-full">{content}</div>;
};

export default compose(
  withProfile,
  withPreferencesDashboard,
  withAuth,
)(PersonalInformationForm);
