import { GET_PROFILE, UPDATE_PROFILE, UPLOAD_AVATAR } from 'modules/v2/store/constants';

export const updateProfile = ({ firstName, lastName, phone, email, companyName }) => ({
  type: UPDATE_PROFILE,
  payload: {
    firstName,
    lastName,
    phone,
    email,
  },
});

export const getProfile = () => ({
  type: GET_PROFILE,
});

export const uploadAvatar = (avatar) => ({
  type: UPLOAD_AVATAR,
  payload: {
    avatar,
  },
});
