import withStore from 'store/StoreContainer';
import { getUserPreferences, preferencesSelector, updatePreferences } from 'modules/v2/store';

const mapStateToProps = (state) => ({
  preferences: preferencesSelector(state),
});

const mapDispatchToProps = {
  updatePreferences,
  getUserPreferences,
};

export default withStore(mapStateToProps, mapDispatchToProps);
