import { UPDATE_PASSWORD, UPDATE_PREFERENCES, GET_USER_PREFERENCES } from 'modules/v2/store/constants';

export const updatePreferences = ({ name, email, phone, brokerInfo, headshotId, publicAddress, brokerLogo, }) => ({
  type: UPDATE_PREFERENCES,
  payload: {
    name,
    email,
    phone,
    brokerInfo,
    headshotId,
    publicAddress,
    brokerLogo,
  },
});

export const updatePassword = (oldPassword, newPassword) => ({
  type: UPDATE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
  },
});


export const getUserPreferences = () => ({
  type: GET_USER_PREFERENCES,
});
