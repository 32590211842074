import { spawn } from 'redux-saga/effects';

import { watchUpdatePassword } from './updatePassword';
import { watchUpdatePreferences } from './updatePreferences';
import { watchGetUserPreferences } from './getUserPreferences';

export default function* saga() {
  yield spawn(watchUpdatePassword);
  yield spawn(watchUpdatePreferences);
  yield spawn(watchGetUserPreferences);
}
