import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchUserPreferences, resource } from 'modules/api';
import { GET_USER_PREFERENCES } from 'modules/v2/store/constants';

export function* onGetUserPreferences() {
  console.log('CHEGOU AQUI');
  console.log('CHEGOU AQUI');
  console.log('CHEGOU AQUI');
  console.log('CHEGOU AQUI');
  try {
    const request = resource(GET_USER_PREFERENCES, fetchUserPreferences);
    const payload = yield call(request);
    console.log('#######################################');
    console.log('PAYLOAD', { onGetUserPreferences: payload });
    console.log('#######################################');
    console.log({ onGetUserPreferences: request });
    console.log('#######################################');
    yield put({
      type: `${GET_USER_PREFERENCES}_SUCCEEDED`,
      payload: { preferences: request },
    });
  } catch (error) {
    console.log('DEU ERRO');
    console.log('DEU ERRO');
    console.log('DEU ERRO');
    console.log('DEU ERRO');
    console.log('DEU ERRO');

    yield put({
      type: `${GET_USER_PREFERENCES}_FAILED`,
      error,
    });
  }
}

export function* watchGetUserPreferences() {
  yield takeEvery(GET_USER_PREFERENCES, onGetUserPreferences);
}
